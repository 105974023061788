// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = (props: PageProps) => (
  <Layout>
    <SEO title="RSVP" />
    <Link to="/">
      <div className="pt-10 md:pt-20">
    <p className="text-gray-600">⬅ Go back</p>
    <h1 className="text-7xl mb-3 mt-3">RSVP</h1></div></Link>
    <p className="text-md text-gray-700" style={{fontFamily:"bad script"}}>Please respond by <span className="font-semibold">December 31st, 2021</span></p>
  
    <form name="Contact Form" method="POST" data-netlify="true">
      <input type="hidden" name="form-name" value="Contact Form" />
      
      <div className="my-5">
        <label className="mr-5">your name</label>
        <input className="p-3" type="input" name="name" />
      </div>
      <div className="my-5">
        <label className="mr-5">your guest's name(s)</label>
        <input className="p-3" type="input" name="guestname" />
      </div>
      <div className="my-5">
        <label className="mr-5">rsvp - welcome dinner (2/11 @ 6)</label>
        <select className="p-3" name="rsvp - welcome dinner">
          <option value="coming">coming</option>
          <option value="nope">nope</option>
        </select>
      </div>
      <div className="my-5">
        <label className="mr-5">rsvp - ceremony & celebration (2/12 @ 5)</label>
        <select className="p-3" name="rsvp - celebration">
        <option value="coming">coming</option>
          <option value="nope">nope</option>
        </select>
      </div>
      <div className="my-5">
        <label className="mr-5">dinner preference</label>
        <select className="p-3" name="dinner pref">
        <option value="no selecttion">select one</option>
        <option value="beef">beef</option>
        <option value="chicken">chicken</option>
          <option value="fish">fish</option>
          <option value="veg">veg</option>
        </select>
      </div>
      <div className="my-5">
        <label className="mr-5">anything else?</label>
        <p className="text-sm mb-2">e.g. dinner preference for guests</p>
        <textarea className="p-3 w-full mt-5" name="extrainfo" />
      </div>
      {/* <div className="my-5">
        <label className="mr-5">your email address:</label>
        <input className="p-3" type="email" name="email" />
      </div> */}
      {/* <div className="my-5">
        <label className="mr-5">Message:</label>
        <textarea className="p-3" name="message" />
      </div> */}
      <button className="mt-5 w-full p-5 text-xl font-medium outline-black" type="submit">submit RSVP</button>
    </form>

  </Layout>
)

export default SecondPage
